import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import { executeGoogle } from "../actions/googlesheetActions";
import { TooltipIcon } from "../components/tooltip";
import { getSheetDetails } from "../actions/sheetActions";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { postAttendance } from "../actions/attendanceActions";
import { getAttendanceSummaryUpdate } from "../actions/attendanceSummaryActions";

const SettingsView = ({ history }) => {
 const [message, setMessage] = useState(null);
 const [selectedFile, setSelectedFile] = useState(null);
 //const [jsonData, setJsonData] = useState(null);

 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 const googlesheetExecute = useSelector((state) => state.googlesheetExecute);
 const { isExecuteGoogleFinished } = googlesheetExecute;

 const attendanceDataEnter = useSelector((state) => state.attendanceDataEnter);
 const { loading, progress, success, error } = attendanceDataEnter;

 useEffect(() => {
  if (userInfo && userInfo.isAdmin) {
   dispatch(getSheetDetails(spreadsheetNameToImport));
   //if (isExecuteGoogleFinished) {
   // setMessage(
   //  "Import All Employees from Google Spreadsheet to server database have done!"
   // );
   //}
   if (success && progress === 100) {
    setMessage("Upload attendance file complete!");
   }
   if (error) {
    setMessage(error);
   }
  } else {
   history.push("/login");
  }
 }, [
  dispatch,
  history,
  userInfo,
  spreadsheetNameToImport,
  isExecuteGoogleFinished,
  success,
  progress,
  loading,
  error,
  message,
 ]);

 const executeGoogleHandler = (e) => {
  e.preventDefault();
  dispatch(executeGoogle());
  dispatch(getAttendanceSummaryUpdate(0));
  setMessage("Updating!");
 };

 const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]);
 };

 const handleFileUpload = () => {
  if (!selectedFile) {
   alert("No file selected!");
   return;
  }
  if (progress > 0 && progress < 100) {
   alert("Upload in progress!");
   return;
  }
  const reader = new FileReader();

  reader.onload = (e) => {
   const data = new Uint8Array(e.target.result);
   const workbook = XLSX.read(data, { type: "array" });
   const firstSheetName = workbook.SheetNames[0];
   const worksheet = workbook.Sheets[firstSheetName];
   const json = XLSX.utils.sheet_to_json(worksheet);
   //setJsonData(json);
   dispatch(postAttendance(json)); // Use json directly here
  };

  reader.readAsArrayBuffer(selectedFile);
 };

 return (
  <Container fluid="md">
   <ListGroup>
    <h2>Settings</h2>
    {message && (
     <Message
      variant={message === "Updating!" ? "info" : "success"}
      useTimer={message === "Updating!" ? false : true}
     >
      {message}
     </Message>
    )}
    {
     <>
      <Form.Label>
       Update employees database on server{" "}
       <TooltipIcon text="Import all valid employees data from googlesheets to server database" />
      </Form.Label>
      {sheet ? (
       <>
        <Row style={{ fontSize: "80%" }}>
         <Col md="auto">Last updated Googlesheet:</Col>
         <Col md="auto">
          {"at "}
          {sheet.lastModifiedTime ? (
           dayjs(sheet.lastModifiedTime).format("D-MMM-YYYY HH:mm:ss wib")
          ) : (
           <></>
          )}
         </Col>
        </Row>
        <Row style={{ fontSize: "80%" }}>
         <Col md="auto">Last updated employee on server:</Col>
         <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
         <Col md="auto">
          {"at "}
          {sheet.employeeLastUpdatedAt
           ? dayjs(sheet.employeeLastUpdatedAt).format(
              "D-MMM-YYYY HH:mm:ss wib"
             )
           : null}
         </Col>
        </Row>
       </>
      ) : (
       <></>
      )}
      <Row>
       <Col>
        <button className="button" onClick={executeGoogleHandler}>
         Update
        </button>
       </Col>
      </Row>
      <Row>
       <Col>
        <br />
       </Col>
      </Row>
      <Row>
       <Col>
        <Form.Label>
         Import attendance data from excel file (format.xlsx, .xls)
        </Form.Label>
       </Col>
      </Row>
      <Row>
       <Col>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
        {/*jsonData && <pre>{JSON.stringify(jsonData, null, 2)}</pre>
         */}
       </Col>
      </Row>
      <br />
      <Row>
       <Col>
        <button
         className="button"
         onClick={handleFileUpload}
         disabled={progress > 0 && progress < 100 ? true : false}
        >
         {progress > 0 && progress < 100
          ? `Uploading... ${progress}%`
          : `Upload`}
        </button>
       </Col>
      </Row>
     </>
    }
   </ListGroup>
  </Container>
 );
};

export default SettingsView;
