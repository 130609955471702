import {
 PROFILE_PICTURE_UPLOAD_REQUEST,
 PROFILE_PICTURE_UPLOAD_SUCCESS,
 PROFILE_PICTURE_UPLOAD_ERROR,
} from "../constants/profilePictureConstant";

export const profilePictureUploadReducer = (state = {}, action) => {
 switch (action.type) {
  case PROFILE_PICTURE_UPLOAD_REQUEST:
   return { ...state, loading: true };
  case PROFILE_PICTURE_UPLOAD_SUCCESS:
   return { ...state, loading: false, file: action.payload };
  case PROFILE_PICTURE_UPLOAD_ERROR:
   return { ...state, loading: false, error: action.payload };
  default:
   return state;
 }
};
