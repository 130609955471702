export const ATTENDANCE_SUMMARY_DATA_ENTER_REQUEST = "ATTENDANCE_SUMMARY_DATA_ENTER_REQUEST";
export const ATTENDANCE_SUMMARY_DATA_ENTER_SUCCESS = "ATTENDANCE_SUMMARY_DATA_ENTER_SUCCESS";
export const ATTENDANCE_SUMMARY_DATA_ENTER_FAIL = "ATTENDANCE_SUMMARY_DATA_ENTER_FAIL";
export const ATTENDANCE_SUMMARY_DATA_ENTER_RESET = "ATTENDANCE_SUMMARY_DATA_ENTER_RESET";

export const ATTENDANCE_SUMMARY_ANALYSIS_REQUEST = "ATTENDANCE_SUMMARY_ANALYSIS_REQUEST";
export const ATTENDANCE_SUMMARY_ANALYSIS_SUCCESS = "ATTENDANCE_SUMMARY_ANALYSIS_SUCCESS";
export const ATTENDANCE_SUMMARY_ANALYSIS_FAIL = "ATTENDANCE_SUMMARY_ANALYSIS_FAIL";
export const ATTENDANCE_SUMMARY_ANALYSIS_RESET = "ATTENDANCE_SUMMARY_ANALYSIS_RESET";

export const ATTENDANCE_SUMMARY_DELETE_REQUEST = "ATTENDANCE_SUMMARY_DELETE_REQUEST";
export const ATTENDANCE_SUMMARY_DELETE_SUCCESS = "ATTENDANCE_SUMMARY_DELETE_SUCCESS";
export const ATTENDANCE_SUMMARY_DELETE_FAIL = "ATTENDANCE_SUMMARY_DELETE_FAIL";
export const ATTENDANCE_SUMMARY_DELETE_RESET = "ATTENDANCE_SUMMARY_DELETE_RESET";

export const ATTENDANCE_SUMMARY_DETAILS_REQUEST = "ANTTENDANCE_DETAILS_REQUEST";
export const ATTENDANCE_SUMMARY_DETAILS_SUCCESS = "ANTTENDANCE_DETAILS_SUCCESS";
export const ATTENDANCE_SUMMARY_DETAILS_ERROR = "ANTTENDANCE_DETAILS_ERROR";

export const ATTENDANCE_SUMMARY_UPDATE_REQUEST = "ATTENDANCE_SUMMARY_UPDATE_REQUEST";
export const ATTENDANCE_SUMMARY_UPDATE_SUCCESS = "ATTENDANCE_SUMMARY_UPDATE_SUCCESS";
export const ATTENDANCE_SUMMARY_UPDATE_ERROR = "ATTENDANCE_SUMMARY_UPDATE_ERROR";
export const ATTENDANCE_SUMMARY_UPDATE_RESET = "ATTENDANCE_SUMMARY_UPDATE_RESET";