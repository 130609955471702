import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import Paginate from "../components/paginate";
import { listEmployees } from "../actions/employeeActions";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { BsArrowDownRightSquareFill } from "react-icons/bs";
import { getWorkDuration } from "../utils/utils";

const EmployeesTableView = ({
 keyword,
 pageNumber,
 isActiveEmployee,
 outlet,
}) => {
 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 useEffect(() => {
  if (!employees) {
   dispatch(listEmployees(keyword, pageNumber));
  }
 }, [dispatch, keyword, pageNumber, employees]);

 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems;
   if (items.length) {
    sortableItems = [...items];
   } else {
    sortableItems = items;
   }

   if (sortConfig !== null) {
    if (items.length) {
     sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
     });
    }
   }
   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
   <table striped bordered hover responsive>
    <thead style={{ position: "sticky", top: 0 }}>
     <tr>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={getClassNamesFor("name")}
       >
        NAME
       </button>
      </th>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("position")}
        className={getClassNamesFor("position")}
       >
        POSITION
       </button>
      </th>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("status")}
        className={getClassNamesFor("status")}
       >
        STATUS
       </button>
      </th>
      <th style={{ textAlign: "right" }}>
       <button
        type="button"
        onClick={() => requestSort("dateIn")}
        className={getClassNamesFor("dateIn")}
       >
        DATE IN
       </button>
      </th>
      <th style={{ textAlign: "center" }}>WORKING PERIOD</th>
      {!isActiveEmployee && (
       <th style={{ textAlign: "right" }}>
        <button
         type="button"
         onClick={() => requestSort("dateResign")}
         className={getClassNamesFor("dateResign")}
        >
         DATE RESIGNED
        </button>
       </th>
      )}
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>
     {userInfo.isAdmin ||
     userInfo.isLeader ||
     userInfo.isCompanyLeader ||
     userInfo.isSuperUser ? (
      items.map((item) =>
       isActiveEmployee ? (
        (!item.dateResign && outlet === item.outlet) ||
        (!item.dateResign && outlet === "ALL OUTLETS") ? (
         <tr key={item._id}>
          <td>
           <Link to={`/employee/${item._id}`}>
            <BsArrowDownRightSquareFill
             style={{ color: "#5b9bd5", fontSize: "80%" }}
            />{" "}
            {item.name}
           </Link>
          </td>
          <td>{item.position}</td>
          <td>{item.status}</td>
          <td style={{ textAlign: "right" }}>
           {item.dateIn ? dayjs(item.dateIn).format("DD-MMM-YYYY") : null}
          </td>
          <td style={{ textAlign: "right" }}>
           {item.dateIn && getWorkDuration(item.dateIn, item.dateResign)}
          </td>
          <td style={{ textAlign: "left" }}>
           {item.dateResign && dayjs(item.dateResign).format("DD-MMM-YYYY")}
          </td>
         </tr>
        ) : null
       ) : (item.dateResign && outlet === item.outlet) ||
         (item.dateResign && outlet === "ALL OUTLETS") ? (
        <tr key={item._id}>
         <td>
          <Link to={`/employee/${item._id}`}>
           <BsArrowDownRightSquareFill
            style={{ color: "#5b9bd5", fontSize: "80%" }}
           />{" "}
           {item.name}
          </Link>
         </td>
         <td>{item.position}</td>
         <td>{item.status}</td>
         <td style={{ textAlign: "right" }}>
          {item.dateIn ? dayjs(item.dateIn).format("DD-MMM-YYYY") : null}
         </td>
         <td style={{ textAlign: "right" }}>
          {getWorkDuration(item.dateIn, item.dateResign)}
         </td>
         <td style={{ textAlign: "right" }}>
          {item.dateResign && dayjs(item.dateResign).format("DD-MMM-YYYY")}
         </td>
        </tr>
       ) : null
      )
     ) : (
      <tr key={items._id}>
       <td>
        <Link to={`/employee/${items._id}`}>
         <BsArrowDownRightSquareFill
          style={{ color: "#5b9bd5", fontSize: "80%" }}
         />{" "}
         {items.name}
        </Link>
       </td>
       <td>{items.position}</td>
       <td>{items.status}</td>
       <td style={{ textAlign: "right" }}>
        {items.dateIn ? dayjs(items.dateIn).format("DD-MMM-YYYY") : null}
       </td>
       <td style={{ textAlign: "right" }}>
        {getWorkDuration(items.dateIn, items.dateResign)}
       </td>
       <td>
        {items.dateResign && dayjs(items.dateResign).format("DD-MMM-YYYY")}
       </td>
      </tr>
     )}
    </tbody>
   </table>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={employees} />
   )}
   <Paginate pages={pages} page={page} isAdmin={true} />
  </>
 );
};

export default EmployeesTableView;
