import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";

const TooltipIcon = ({ text, color = "rgba(52, 58, 64, 0.8)" }) => {
 const renderTooltip = (props) => <Tooltip id="tooltip">{props}</Tooltip>;

 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 0, hide: 200 }}
   overlay={renderTooltip(text)}
  >
   <span>
    <FaQuestionCircle size={15} color={color} />
   </span>
  </OverlayTrigger>
 );
};

const TooltipButton = ({
 text,
 color = "rgba(52, 58, 64, 0.8)",
 buttonText,
 buttonClassName,
 key,
 status,
}) => {
 // Function to replace newline characters with <br />
 const formatText = (text) => {
  if (!text) return "";
  return text.replace(/\n/g, "<br />");
 };

 const renderTooltip = (props) => (
  <Tooltip id="tooltip">
   <span dangerouslySetInnerHTML={{ __html: formatText(props) }} />
  </Tooltip>
 );

 const [show, setShow] = useState(false);

 useEffect(() => {
  if (show) {
   const timer = setTimeout(() => setShow(false), 2000);
   return () => clearTimeout(timer);
  }
 }, [show]);
 const backgroundColor = (status) => {
  switch (status) {
   case "hadir":
    return "rgb(169, 199, 169)";
   case "sakit":
    return "rgb(251, 217, 117)";
   default:
    return "rgb(242, 153, 153)";
  }
 };
 /* "select...",
    "hadir",
    "sakit",
    "sakit non surat",
    "izin",
    "absen",
    "cuti tahunan",
    "cuti istri melahirkan",
    "cuti melahirkan",
    "cuti anggota keluarga satu rumah meninggal",*/
 const icon = (status) => {
  switch (status) {
   case "hadir":
    return buttonText;
   case "cuti tahunan":
    return "cuti";
   case "cuti istri melahirkan":
    return "cuti";
   case "cuti melahirkan":
    return "cuti";
   case "cuti anggota keluarga satu rumah meninggal":
    return "cuti";
   case undefined:
    return <i className="fas fa-times" style={{ color: "red" }}></i>;
   default:
    return status;
  }
 };
 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 200, hide: 200 }}
   overlay={renderTooltip(text)}
  >
   <button
    type="button"
    className={
     status === "hadir"
      ? "attendance-hadir-button"
      : "attendance-tidakhadir-button"
    }
    style={{
     backgroundColor: backgroundColor(status),
     fontWeight: status === "hadir" ? "" : "500",
    }}
   >
    {icon(status)}
   </button>
  </OverlayTrigger>
 );
};

export { TooltipIcon, TooltipButton };
