import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import { getEmployeeDetails, deleteEmployee } from "../actions/employeeActions";
import { EMPLOYEE_UPDATE_RESET } from "../constants/employeeConstant";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { getAge, getWorkDuration } from "../utils/utils";
import { updateEmployee } from "../actions/employeeActions";
import { EMPLOYEE_DETAILS_REQUEST } from "../constants/employeeConstant";
import { options } from "../utils/utils";
import { WhatsAppLink } from "../components/whatsapp";
import { EmailLink } from "../components/email";
import UploadProfilePicture from "../components/uploadProfilePicture";

const EmployeeDetailsView = ({ match, history }) => {
 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [outlet, setOutlet] = useState("");
 const [ktpID, setKtpID] = useState("");
 const [addressOfKTP, setAddressOfKTP] = useState("");
 const [address, setAddress] = useState("");

 const [status, setStatus] = useState("");
 const [position, setPosition] = useState("");
 const [sex, setSex] = useState("");
 const [bpjsKesehatan, setBpjsKesehatan] = useState("");
 const [bpjsKesehatanDescription, setBpjsKesehatanDescription] = useState("");
 const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState("");
 const [bpjsKetenagakerjaanDescription, setBpjsKetenagakerjaanDescription] =
  useState("");

 const [placeOfBirth, setPlaceOfBirth] = useState("");
 const [dateOfBirth, setDateOfBirth] = useState(null);
 const [dateIn, setDateIn] = useState(null);
 const [dateResign, setDateResign] = useState(null);
 const [phone, setPhone] = useState("");
 const [email, setEmail] = useState("");

 const [isDisabled, setIsDisabled] = useState(true);

 const dispatch = useDispatch();
 const employeeDetails = useSelector((state) => state.employeeDetails);
 const { loading, employee } = employeeDetails;
 const employeeUpdate = useSelector((state) => state.employeeUpdate);
 const { success: successUpdate } = employeeUpdate;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const refresh = () => {
  setEmployeeID(employee.employeeID);
  setName(employee.name);
  setOutlet(employee.outlet);
  setKtpID(employee.ktpID);
  setAddressOfKTP(employee.addressOfKTP);
  setAddress(employee.address);
  setStatus(employee.status);
  setPosition(employee.position);
  setSex(employee.sex);
  setBpjsKesehatan(employee.bpjsKesehatan);
  setBpjsKesehatanDescription(employee.bpjsKesehatanDescription);
  setBpjsKetenagakerjaan(employee.bpjsKetenagakerjaan);
  setBpjsKetenagakerjaanDescription(employee.bpjsKetenagakerjaanDescription);
  setPlaceOfBirth(employee.placeOfBirth);
  setDateOfBirth(employee.dateOfBirth);
  setDateIn(employee.dateIn);
  setDateResign(employee.dateResign);
  setPhone(employee.phone);
  setEmail(employee.email);
 };

 useEffect(() => {
  if (!loading && !userInfo) {
   history.push("/login");
  }
  if (!employee || match.params.id !== employee._id) {
   dispatch({ type: EMPLOYEE_DETAILS_REQUEST });
   dispatch(getEmployeeDetails(match.params.id));
  }
  if (employee) {
   refresh();
  }
  if (successUpdate) {
   dispatch({ type: EMPLOYEE_UPDATE_RESET });
   dispatch(getEmployeeDetails(match.params.id));
  } // eslint-disable-next-line
 }, [dispatch, history, successUpdate, userInfo, loading, employee, match]);

 //const navigateToEdit = () => {
 // history.push({
 //  pathname: `/employee/edit/${employee._id}`,
 //  state: { employeeProps: employee },
 // });
 //};

 const deleteEmployeeHandler = () => {
  if (window.confirm("Are you sure")) {
   dispatch(deleteEmployee(employee._id));
   history.push("/employeeList");
  }
 };

 const submitHandler = () => {
  if (!employee) {
   return;
  }

  dispatch(
   updateEmployee({
    _id: employee._id,
    employeeID,
    name,
    outlet,
    ktpID,
    addressOfKTP,
    address,
    status,
    position,
    sex,
    bpjsKesehatan,
    bpjsKesehatanDescription,
    bpjsKetenagakerjaan,
    bpjsKetenagakerjaanDescription,
    placeOfBirth,
    dateOfBirth,
    dateIn,
    dateResign,
    phone,
    email,
   })
  );
  dispatch(getEmployeeDetails(match.params.id));
 };

 const defaultOptions = "Select...";
 const sexOptions = options([defaultOptions, "Men", "Women"]);

 // Fungsi untuk mengubah tanggal menjadi format yang diterima input type="date"
 const dateForPicker = (date) => {
  if (date) {
   return dayjs(date).format("YYYY-MM-DD");
  }
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dayjs(dateString).toDate();
 };

 const getAgeLong = () => {
  return getAge(dateOfBirth);
 };
 const statusOptions = options([defaultOptions, "TETAP", "PROBATION"]);

 return (
  <>
   <Row>
    <Col md={4}>
     <GoBackButton onClick={() => history.goBack()}></GoBackButton>
    </Col>
    <Col md={4}>
     <h3>{isDisabled ? "Employee Details" : "Edit Employee"}</h3>
    </Col>
    <Col md={3}>
     {userInfo.isAdmin ? (
      isDisabled ? (
       <button
        type="button"
        className="button"
        onClick={() => setIsDisabled(false)}
        style={{ gap: "20px" }}
       >
        Edit
       </button>
      ) : (
       <>
        <button
         type="button"
         className="button"
         onClick={() => {
          setIsDisabled(true);
          refresh();
         }}
        >
         Cancel
        </button>
        <button
         type="submit"
         className="button"
         onClick={() => {
          submitHandler();
          setIsDisabled(true);
         }}
         disabled={isDisabled}
        >
         Apply
        </button>
       </>
      )
     ) : null}
    </Col>
    <Col md={1}>
     {!isDisabled && userInfo.isAdmin && (
      <button
       className="button"
       style={{ justifyContent: "right" }}
       onClick={deleteEmployeeHandler}
      >
       <i className="fas fa-trash"></i>
      </button>
     )}
    </Col>
   </Row>

   <UploadProfilePicture />

   <Row>
    <Col md={3}>
     <h4 className="underline">Work</h4>
    </Col>
    <Col md={1}>
     {phone && <WhatsAppLink phone={phone} />}
     {email && <EmailLink email={email} />}
    </Col>
   </Row>

   <Row>
    <Col md={4}>
     <Form.Group controlId="employeeID">
      <Form.Label>Employee ID</Form.Label>
      <Form.Control
       placeholder="Your employee ID"
       value={employeeID}
       onChange={(e) => setEmployeeID(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="status">
      <Form.Label>
       Status<span>*</span>
      </Form.Label>
      <Form.Control
       as={isDisabled ? "span" : "select"}
       placeholder=""
       value={status}
       onChange={(e) => setStatus(e.target.value)}
       disabled={isDisabled}
      >
       {isDisabled ? status : statusOptions}
      </Form.Control>
     </Form.Group>
     <Form.Group controlId="bpjsKesehatan">
      <Form.Label>BPJS Kesehatan</Form.Label>
      <Form.Control
       placeholder="Enter BPJS Kesehatan"
       value={bpjsKesehatan}
       onChange={(e) => setBpjsKesehatan(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="bpjsKetenagakerjaan">
      <Form.Label>BPJS Ketenagakerjaan</Form.Label>
      <Form.Control
       placeholder="Enter BPJS Ketenagakerjaan"
       value={bpjsKetenagakerjaan}
       onChange={(e) => setBpjsKetenagakerjaan(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="name">
      <Form.Label>Name</Form.Label>
      <Form.Control
       placeholder="Your full name"
       value={name}
       onChange={(e) => setName(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="position">
      <Form.Label>Position</Form.Label>
      <Form.Control
       placeholder="Enter position"
       value={position}
       onChange={(e) => setPosition(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="bpjsKesehatanDescription">
      <Form.Label>BPJS Kesehatan Description</Form.Label>
      <Form.Control
       placeholder="Enter BPJS Kesehatan Description"
       value={bpjsKesehatanDescription}
       onChange={(e) => setBpjsKesehatanDescription(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="bpjsKetenagakerjaanDescription">
      <Form.Label>BPJS Ketenagakerjaan Description</Form.Label>
      <Form.Control
       placeholder="Enter BPJS Ketenagakerjaan Description"
       value={bpjsKetenagakerjaanDescription}
       onChange={(e) => setBpjsKetenagakerjaanDescription(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="outlet">
      <Form.Label>Outlet</Form.Label>
      <Form.Control
       placeholder="Enter outlet"
       value={outlet}
       onChange={(e) => setOutlet(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="dateIn">
      <Row>
       <Col>
        <Form.Label>Date Join</Form.Label>
       </Col>
       <Col md={9}>
        <Form.Text className="text-muted">
         {`Duration: ${getWorkDuration(dateIn, dateResign)}`}
        </Form.Text>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateIn)}
       onChange={(e) => setDateIn(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>

     <Form.Group controlId="dateResign">
      <Row>
       <Col>
        <Form.Label>Date Resign</Form.Label>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateResign)}
       onChange={(e) => setDateResign(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>
    </Col>
   </Row>
   <Row>
    <Col md={4}>
     <h4 className="underline">Personal Data</h4>
    </Col>
   </Row>
   <Row>
    <Col md={4}>
     <Form.Group controlId="ktpID">
      <Form.Label>KTP</Form.Label>
      <Form.Control
       placeholder="Enter KTP ID"
       value={ktpID}
       onChange={(e) => setKtpID(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="placeOfBirth">
      <Form.Label>Place of Birth</Form.Label>
      <Form.Control
       placeholder="Enter place of birth"
       value={placeOfBirth}
       onChange={(e) => setPlaceOfBirth(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="addressOfKTP">
      <Form.Label>Address of KTP</Form.Label>
      <Form.Control
       placeholder="Enter address of KTP"
       value={addressOfKTP}
       onChange={(e) => setAddressOfKTP(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="dateOfBirth">
      <Row>
       <Col>
        <Form.Label>Date of Birth </Form.Label>
       </Col>
       <Col md={8}>
        <Form.Text className="text-muted">{getAgeLong()}</Form.Text>
       </Col>
      </Row>
      <Form.Control
       type="date"
       value={dateForPicker(dateOfBirth)}
       onChange={(e) => setDateOfBirth(dateFromDateString(e.target.value))}
       disabled={isDisabled}
      />
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="address">
      <Form.Label>Address of Domicile</Form.Label>
      <Form.Control
       placeholder="Enter address of domicile"
       value={address}
       onChange={(e) => setAddress(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
     <Form.Group controlId="sex">
      <Form.Label>Sex</Form.Label>
      <Form.Control
       as="select"
       placeholder=""
       value={sex}
       onChange={(e) => setSex(e.target.value)}
       disabled={isDisabled}
      >
       {sexOptions}
      </Form.Control>
     </Form.Group>
    </Col>
   </Row>
   <Row>
    <Col md={3}>
     <h4 className="underline">Contact</h4>
    </Col>
    <Col md={1}>
     {phone && <WhatsAppLink phone={phone} />}
     {email && <EmailLink email={email} />}
    </Col>
   </Row>
   <Row>
    <Col md={4}>
     <Form.Group controlId="phone">
      <Row>
       <Col>
        <Form.Label>Phone</Form.Label>
       </Col>
      </Row>
      <Form.Control
       placeholder="Enter phone"
       value={phone}
       onChange={(e) => setPhone(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}>
     <Form.Group controlId="email">
      <Form.Label>Email</Form.Label>
      <Form.Control
       placeholder="Enter email"
       value={email}
       onChange={(e) => setEmail(e.target.value)}
       disabled={isDisabled}
      ></Form.Control>
     </Form.Group>
    </Col>
    <Col md={4}></Col>
   </Row>
  </>
 );
};

export default EmployeeDetailsView;
