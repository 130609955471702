import { React, useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

const Message = ({ variant, children, useTimer = false }) => {
 const [show, setShow] = useState(true);

 useEffect(() => {
  if (useTimer) {
   const timer = setTimeout(() => {
    setShow(false);
   }, 5000); // Alert will dismiss after 5 seconds

   return () => clearTimeout(timer); // Cleanup timer on unmount
  }
 }, [useTimer]);

 return show ? (
  <Alert variant={variant} onClose={() => setShow(false)} dismissible>
   {children}
  </Alert>
 ) : null;
};
Message.defaultProps = {
 variant: "info",
};

export default Message;
