/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listEmployees } from "../actions/employeeActions.jsx";
import { Route, useLocation } from "react-router-dom";
import SearchBox from "../components/searchBox.jsx";
import { Row, Col, Container, Form } from "react-bootstrap";
import { TooltipIcon } from "../components/tooltip.jsx";
import { capitalizeEachWord, options } from "../utils/utils.jsx";
import dayjs from "dayjs";
import { getSheetDetails } from "../actions/sheetActions.jsx";
import { getAnalysisAttendanceSummaryByDate } from "../actions/attendanceSummaryActions.jsx";
import AddAttendanceTableView from "./addAttendanceTableView.jsx";
import { Toggle } from "rsuite";

const AddAttendanceView = ({ match, history }) => {
 const location = useLocation(); // Added for reading the URL
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const [isActiveEmployee, setIsActiveEmployee] = useState(true);
 const [outlet, setOutlet] = useState(
  userInfo.isAdmin ? "SRIWIJAYA" : userInfo.outlet
 );
 //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
 //console.log(`userInfo.outlet: ${userInfo.outlet}`);

 const lastMonth = dayjs().subtract(1, "month")?.month();
 const thisMonth = dayjs()?.month();
 const [month, setMonth] = useState(
  dayjs().get("date") > 15 ? thisMonth : lastMonth
 );

 //console.log(`month: ${month}\nday:${dayjs().get("date")}`);
 const [year, setYear] = useState(parseInt(dayjs().year()));
 const [date, setDate] = useState(
  dayjs(`${year}-${month}-26`)?.startOf("day").toDate()
 );
 const [details, setDetails] = useState(true);

 const handleToggleDetails = () => {
  setDetails(!details);
 };

 const handleIsActiveEmployee = () => {
  setIsActiveEmployee(!isActiveEmployee);
 };

 const attendanceSummaryAnalysis = useSelector(
  (state) => state.attendanceSummaryAnalysis
 );
 const { attendanceSummary } = attendanceSummaryAnalysis;

 const keyword = match.params.keyword;

 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 const handleOutletChange = (e) => {
  const selectedOutlet = e.target.value;
  setOutlet(selectedOutlet);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("outlet", selectedOutlet);
  history.push({ search: searchParams.toString() });
 };

 // eslint-disable-next-line
 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }
  dispatch(getSheetDetails(spreadsheetNameToImport));
  dispatch(
   getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
  );
  // Update outlet state from URL if present
  const searchParams = new URLSearchParams(location.search);
  const outletParam = searchParams.get("outlet");
  if (outletParam) {
   setOutlet(outletParam); // Set the outlet from URL
  }
  if (!outlet && userInfo.outlet) {
   setOutlet(userInfo.outlet);
  }
 }, [
  isActiveEmployee,
  keyword,
  userLoading,
  userInfo,
  spreadsheetNameToImport,
  outlet,
  date,
  location,
 ]);

 const outletOptions = options([
  "SRIWIJAYA",
  "SUDIRMAN",
  "SETIABUDI",
  "MAHAKAM",
  "MANAGEMENT",
  "KHALID MEDAN",
  "KHALID BINTARO",
 ]);

 const handleMonthChange = (e) => {
  const newMonth = parseInt(e.target.value);
  setMonth(newMonth);
  setDate(dayjs(`${year}-${newMonth}-26`).startOf("day").toDate());
 };

 const handleYearChange = (e) => {
  const newYear = parseInt(e.target.value);
  setYear(newYear);
  setDate(dayjs(`${newYear}-${month}-26`).startOf("day").toDate());
 };

 return (
  <>
   <Container>
    <Row style={{ justifyContent: "center" }}>
     <Col md="auto">
      <h3>ATTENDANCE</h3>
     </Col>
    </Row>
   </Container>
   <Row>
    <Col>
     <h6>{`${dayjs(date).format("DD MMMM YYYY")} - ${dayjs(date)
      .add(1, "month")
      .subtract(1, "day")
      .format("DD MMMM YYYY")}`}</h6>
    </Col>
   </Row>

   <Row>
    <Col md="auto">
     <div style={{ display: "flex", alignItems: "center" }}>
      <span
       style={{
        marginRight: "10px",
        color: "black",
        fontSize: "12px",
       }}
      >
       {`${isActiveEmployee ? "Active Employee" : "Resigned Employee"}`}
      </span>
      <Toggle checked={isActiveEmployee} onChange={handleIsActiveEmployee} />
     </div>
    </Col>
    <Col style={{ justifyContent: "right" }}>
     <Form inline>
      {userInfo.isAdmin ? (
       <Form.Group controlId="outlet">
        <Form.Control
         as="select"
         value={outlet}
         onChange={handleOutletChange}
         style={{
          color: "white",
          backgroundColor: "#ff0000",
          borderRadius: "8px",
         }}
        >
         {outletOptions}
        </Form.Control>
       </Form.Group>
      ) : (
       <strong>{capitalizeEachWord(outlet)}</strong>
      )}
     </Form>
    </Col>
    <Col style={{ justifyContent: "right" }}>
     <Form inline>
      <Form.Group>
       <Form.Control
        as="select"
        value={month}
        onChange={handleMonthChange}
        style={{
         color: "white",
         backgroundColor: "#ff0000",
         borderRadius: "8px",
        }}
       >
        {Array.from({ length: 12 }, (v, i) => (
         <option key={i} value={i + 1}>
          {new Date(0, i).toLocaleString("default", {
           month: "long",
          })}
         </option>
        ))}
       </Form.Control>

       <Form.Control
        as="select"
        value={year}
        onChange={handleYearChange}
        style={{
         color: "white",
         backgroundColor: "#ff0000",
         borderRadius: "8px",
        }}
       >
        {Array.from({ length: 50 }, (v, i) => (
         <option key={i} value={i + new Date().getFullYear() - 25}>
          {i + new Date().getFullYear() - 25}
         </option>
        ))}
       </Form.Control>
      </Form.Group>
     </Form>
    </Col>
    <Col md="auto">
     <div style={{ display: "flex", alignItems: "center" }}>
      <span
       style={{
        marginLeft: "18px",
        marginRight: "10px",
        color: "black",
        fontSize: "12px",
       }}
      >
       View Details
      </span>
      <Toggle checked={details} onChange={handleToggleDetails} />
     </div>
    </Col>
    <Col md="auto">
     <Route
      render={({ history }) => (
       <SearchBox
        history={history}
        pageName={"attendance"}
        keyword={keyword}
        outlet={outlet}
       />
      )}
     />{" "}
    </Col>
   </Row>

   {sheet ? (
    <Row style={{ fontSize: "80%" }}>
     <Col md="auto">Last updated employee:</Col>
     <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
     <Col md="auto">
      {"at "}
      {sheet.employeeLastUpdatedAt
       ? dayjs(sheet.employeeLastUpdatedAt).format("D-MMM-YYYY HH:mm:ss wib")
       : null}
     </Col>
    </Row>
   ) : (
    <></>
   )}
   {loading ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     {
      <AddAttendanceTableView
       keyword={keyword}
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       date={date}
       details={details}
       userInfo={userInfo}
      />
     }
    </>
   )}
  </>
 );
};

export default AddAttendanceView;
