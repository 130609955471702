export const ATTENDANCE_DATA_ENTER_REQUEST = "ATTENDANCE_DATA_ENTER_REQUEST";
export const ATTENDANCE_DATA_ENTER_PROGRESS = "ATTENDANCE_DATA_ENTER_PROGRESS";
export const ATTENDANCE_DATA_ENTER_SUCCESS = "ATTENDANCE_DATA_ENTER_SUCCESS";
export const ATTENDANCE_DATA_ENTER_COMPLETE = "ATTENDANCE_DATA_ENTER_COMPLETE";
export const ATTENDANCE_DATA_ENTER_FAIL = "ATTENDANCE_DATA_ENTER_FAIL";
export const ATTENDANCE_DATA_ENTER_RESET = "ATTENDANCE_DATA_ENTER_RESET";

export const ATTENDANCE_ANALYSIS_REQUEST = "ATTENDANCE_ANALYSIS_REQUEST";
export const ATTENDANCE_ANALYSIS_SUCCESS = "ATTENDANCE_ANALYSIS_SUCCESS";
export const ATTENDANCE_ANALYSIS_FAIL = "ATTENDANCE_ANALYSIS_FAIL";
export const ATTENDANCE_ANALYSIS_RESET = "ATTENDANCE_ANALYSIS_RESET";

export const ATTENDANCE_DELETE_REQUEST = "ATTENDANCE_DELETE_REQUEST";
export const ATTENDANCE_DELETE_SUCCESS = "ATTENDANCE_DELETE_SUCCESS";
export const ATTENDANCE_DELETE_FAIL = "ATTENDANCE_DELETE_FAIL";
export const ATTENDANCE_DELETE_RESET = "ATTENDANCE_DELETE_RESET";

export const ATTENDANCE_DETAILS_REQUEST = "ANTTENDANCE_DETAILS_REQUEST";
export const ATTENDANCE_DETAILS_SUCCESS = "ANTTENDANCE_DETAILS_SUCCESS";
export const ATTENDANCE_DETAILS_ERROR = "ANTTENDANCE_DETAILS_ERROR";

export const ATTENDANCE_UPDATE_REQUEST = "ATTENDANCE_UPDATE_REQUEST";
export const ATTENDANCE_UPDATE_SUCCESS = "ATTENDANCE_UPDATE_SUCCESS";
export const ATTENDANCE_UPDATE_ERROR = "ATTENDANCE_UPDATE_ERROR";
export const ATTENDANCE_UPDATE_RESET = "ATTENDANCE_UPDATE_RESET";
