import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import FormContainer from "../../components/formContainer";
import { register } from "../../actions/userActions";
import { LandingPageBgImage } from "../../components/backgroundImage";

const RegisterView = ({ location, history }) => {
 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [confirmPassword, setConfirmPassword] = useState("");
 const [message, setMessage] = useState(null);

 const dispatch = useDispatch();

 const userRegister = useSelector((state) => state.userRegister);
 const { loading, error, userInfo } = userRegister;

 const redirect = location.search ? location.search.split("=")[1] : "/";

 useEffect(() => {
  if (userInfo) {
   history.push(redirect);
  }
 }, [history, userInfo, redirect]);

 const submitHandler = (e) => {
  e.preventDefault();
  if (password !== confirmPassword) {
   setMessage("Passwords do not match");
  } else {
   dispatch(register(employeeID, name, email, password));
  }
 };

 return (
  <>
   <LandingPageBgImage>
    <FormContainer>
     {/*eslint-disable-next-line*/}
     <h1></h1>
     {message && <Message variant="danger">{message}</Message>}
     {error && <Message variant="danger">{error}</Message>}
     {loading && <Loader />}
     <Form onSubmit={submitHandler}>
      <Form.Group controlId="employeeID">
       <Form.Label>Employee ID</Form.Label>
       <Form.Control
        type="employeeID"
        placeholder="Enter employee ID"
        value={employeeID}
        onChange={(e) => setEmployeeID(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name">
       <Form.Label>Full Name</Form.Label>
       <Form.Control
        type="name"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="email">
       <Form.Label>Email Address</Form.Label>
       <Form.Control
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="password">
       <Form.Label>Password</Form.Label>
       <Form.Control
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="confirmPassword">
       <Form.Label>Confirm Password</Form.Label>
       <Form.Control
        type="password"
        placeholder="Confirm password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <button type="submit" className="button">
       Register
      </button>
     </Form>

     <Row className="py-3">
      <Col>
       Have an Account?{" "}
       <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
        <strong>Login</strong>
       </Link>
      </Col>
     </Row>
    </FormContainer>
   </LandingPageBgImage>
  </>
 );
};

export default RegisterView;
