/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Employee from "../components/employee";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listEmployees } from "../actions/employeeActions";
import Paginate from "../components/paginate";
import { Route } from "react-router-dom";
import SearchBox from "../components/searchBox";
import {
 Row,
 Col,
 ButtonGroup,
 ToggleButton,
 Container,
 Form,
} from "react-bootstrap";
import EmployeesTableView from "./employeeTableView";
import { TooltipIcon } from "../components/tooltip";
import { options } from "../utils/utils.jsx";
import { getSheetDetails } from "../actions/sheetActions";
import dayjs from "dayjs";

const EmployeeListView = ({ match, history }) => {
 const [isGrid, setIsGrid] = useState(true);
 const activeEmployee = "Active employee";
 const [showActiveEmployee, setShowActiveEmployee] = useState(activeEmployee);
 const [isActiveEmployee, setIsActiveEmployee] = useState(true);
 const [showOutlet, setShowOutlet] = useState("ALL OUTLETS");
 const keyword = match.params.keyword;

 const pageNumber = match.params.pageNumber || 1;
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 // eslint-disable-next-line
 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }

  showActiveEmployee === activeEmployee
   ? setIsActiveEmployee(true)
   : setIsActiveEmployee(false);

  dispatch(getSheetDetails(spreadsheetNameToImport));
  dispatch(listEmployees(keyword, pageNumber));
 }, [
  keyword,
  pageNumber,
  userLoading,
  userInfo,
  showActiveEmployee,
  spreadsheetNameToImport,
  showOutlet,
 ]);

 const showActiveEmployeeOptions = options([
  activeEmployee,
  "Resigned employee",
 ]);

 const showOutletOptions = options([
  "ALL OUTLETS",
  "SRIWIJAYA",
  "SUDIRMAN",
  "SETIABUDI",
  "MAHAKAM",
  "MANAGEMENT",
  "KHALID MEDAN",
  "KHALID BINTARO",
 ]);

 return (
  <>
   <>
    <Container>
     <Row style={{ justifyContent: "center" }}>
      <Col md="auto">
       <h3>Employees</h3>
      </Col>
     </Row>

     <Row className="justify-content-md-center">
      <Col md="auto">
       <ButtonGroup toggle>
        {["Grid", "Table"].map((type) => (
         <ToggleButton
          key={type}
          type="radio"
          variant="outline-info"
          name="radio"
          value={type}
          checked={(isGrid ? "Grid" : "Table") === type}
          onChange={(e) => setIsGrid(e.target.value === "Grid" ? true : false)}
         >
          {type === "Grid" ? <> Grid</> : <> Table </>}
         </ToggleButton>
        ))}
       </ButtonGroup>
      </Col>
     </Row>
    </Container>
    {userInfo.isAdmin ||
    userInfo.isLeader ||
    userInfo.isCompanyLeader ||
    userInfo.isSuperUser ? (
     <Row>
      <Col style={{ justifyContent: "right" }}>
       <Form inline>
        <Form.Group controlId="showActiveEmployee">
         <Form.Control
          as="select"
          value={showActiveEmployee}
          onChange={(e) => setShowActiveEmployee(e.target.value)}
          style={{
           color: "white",
           backgroundColor: "#ff0000",
           borderRadius: "8px",
           fontWeight: "bold",
          }}
         >
          {showActiveEmployeeOptions}
         </Form.Control>
        </Form.Group>
       </Form>
      </Col>
      <Col style={{ justifyContent: "right" }}>
       <Form inline>
        <Form.Group controlId="showOutlet">
         <Form.Control
          as="select"
          value={showOutlet}
          onChange={(e) => setShowOutlet(e.target.value)}
          style={{
           color: "white",
           backgroundColor: "#ff0000",
           borderRadius: "8px",
           fontWeight: "bold",
          }}
         >
          {showOutletOptions}
         </Form.Control>
        </Form.Group>
       </Form>
      </Col>
      <Col md="auto">
       <Route
        render={({ history }) => (
         <SearchBox
          history={history}
          pageName={"employeeList"}
          keyword={keyword}
         />
        )}
       />{" "}
      </Col>
     </Row>
    ) : null}
    {sheet ? (
     <Row style={{ fontSize: "80%" }}>
      <Col md="auto">Last updated employee:</Col>
      <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
      <Col md="auto">
       {"at "}
       {sheet.employeeLastUpdatedAt
        ? dayjs(sheet.employeeLastUpdatedAt).format("D-MMM-YYYY HH:mm:ss wib")
        : null}
      </Col>
     </Row>
    ) : (
     <></>
    )}
   </>
   {loading ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : isGrid ? (
    <>
     <Row>
      {userInfo.isAdmin ||
      userInfo.isLeader ||
      userInfo.isCompanyLeader ||
      userInfo.isSuperUser ? (
       employees.map((employee) =>
        isActiveEmployee ? (
         (!employee.dateResign && showOutlet === employee.outlet) ||
         (!employee.dateResign && showOutlet === "ALL OUTLETS") ? (
          <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
           <Employee stuentDetails={employee} />
          </Col>
         ) : null
        ) : (employee.dateResign && showOutlet === employee.outlet) ||
          (employee.dateResign && showOutlet === "ALL OUTLETS") ? (
         <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
          <Employee stuentDetails={employee} />
         </Col>
        ) : null
       )
      ) : (
       <Col key={employees._id} sm={12} md={6} lg={4} xl={3}>
        <Employee stuentDetails={employees} />
       </Col>
      )}
     </Row>
     <Paginate pages={pages} page={page} keyword={keyword ? keyword : ""} />
    </>
   ) : (
    <>
     <EmployeesTableView
      keyword={keyword}
      pageNumber={pageNumber}
      isActiveEmployee={isActiveEmployee}
      outlet={showOutlet}
     />
    </>
   )}
  </>
 );
};

export default EmployeeListView;
