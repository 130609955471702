import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InstagramLink from "./instagramLink.jsx";
import FacebookLink from "./facebookLink.jsx";
import LinktreeLink from "./linktreeLink.jsx";

const Footer = () => {
 return (
  <Container>
   <Row>
    <Col className="text-center py-1">
     <InstagramLink />
     <LinktreeLink />
     <FacebookLink />
    </Col>
   </Row>
   <Row>
    <Col className="text-center py-1">
     Version 1.0.0 Copyright &copy;Ikhsan Prasetyo
    </Col>
   </Row>
  </Container>
 );
};

export default Footer;
