import axios from "axios";
import {
 PROFILE_PICTURE_UPLOAD_REQUEST,
 PROFILE_PICTURE_UPLOAD_SUCCESS,
 PROFILE_PICTURE_UPLOAD_ERROR,
} from "../constants/profilePictureConstant";

const uploadProfilePicture = (employee, file) => async (dispatch, getState) => {
 try {
  dispatch({ type: PROFILE_PICTURE_UPLOAD_REQUEST });

  const {
   userLogin: { userInfo },
  } = getState();

  const formData = new FormData();
  formData.append("file", file);

  const config = {
   headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${userInfo.token}`, // Fixed template string
   },
  };

  const { data } = await axios.post(
   `/profilePicture/upload/${employee._id}`,
   formData,
   config
  );

  dispatch({
   type: PROFILE_PICTURE_UPLOAD_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: PROFILE_PICTURE_UPLOAD_ERROR,
   payload: error.response?.data?.message || error.message, // Using optional chaining
  });
 }
};

export { uploadProfilePicture };
