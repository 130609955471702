import React from "react";

const InstagramLink = () => {
 return (
  <a
   href="https://www.instagram.com/sinarutama/"
   target="_blank"
   rel="noopener noreferrer"
  >
   <i
    className="fab fa-instagram"
    style={{ fontSize: "1.75em", marginRight: "10px" }}
   />
  </a>
 );
};

export default InstagramLink;
