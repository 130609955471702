import React from "react";

const LinktreeLink = () => {
 return (
  <a
   href="https://www.linktr.ee/sinarutama"
   target="_blank"
   rel="noopener noreferrer"
  >
   <i
    className="fas fa-link"
    style={{ fontSize: "1.5em", marginRight: "10px" }}
   />
  </a>
 );
};

export default LinktreeLink;
