import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uploadProfilePicture } from "../actions/profilePictureActions";
import { getEmployeeDetails } from "../actions/employeeActions";
import { useParams } from "react-router-dom";

const UploadProfilePicture = ({ match, history }) => {
 const [selectedFile, setSelectedFile] = useState(null);
 const [localMessage, setLocalMessage] = useState("");
 const [isDisabled, setIsDisabled] = useState(true);

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;
 const profilePictureUpload = useSelector(
  (state) => state.profilePictureUpload
 );
 const { loading, message, error } = profilePictureUpload;
 const employeeDetails = useSelector((state) => state.employeeDetails);
 const { employee } = employeeDetails;
 const dispatch = useDispatch();
 const { id } = useParams(); // Get the id from the URL params

 useEffect(() => {
  if (!loading && !userInfo) {
   history.push("/login");
  } else if (!employee || id !== employee._id) {
   dispatch(getEmployeeDetails(id));
  }
 }, [dispatch, userInfo, loading, employee, id, history, isDisabled]);

 const handleFileChange = (e) => {
  setSelectedFile(e.target.files[0]);
 };

 const handleUpload = async () => {
  if (!selectedFile) {
   setLocalMessage("Please select a file first.");
   return;
  }

  try {
   if (employee && employee._id && selectedFile) {
    await dispatch(uploadProfilePicture(employee, selectedFile));
    setIsDisabled(true);
    dispatch(getEmployeeDetails(id));
   }
  } catch (error) {
   setLocalMessage(`Error: ${error}`);
  }
 };

 return (
  <div style={{ textAlign: "center" }}>
   {message && <p>{message}</p>}
   {localMessage && <p>{localMessage}</p>}
   {error && <p>{error}</p>}
   {
    <div>
     <img
      src={
       employee && employee.profilePicture
        ? `../${employee.profilePicture}`
        : employee &&
          !employee.profilePicture &&
          `../uploads/images/profilePictures/blank-profile-picture.png`
      }
      alt="Profile"
      style={{
       width: "150px",
       height: "150px",
       borderRadius: "50%",
      }}
     />
     {isDisabled ? (
      <button
       className="button"
       onClick={() => setIsDisabled(false)}
       style={{ verticalAlign: "bottom" }}
      >
       <i className="fas fa-camera" style={{ fontSize: "1.75em" }} />
      </button>
     ) : (
      <button
       className="button"
       onClick={() => setIsDisabled(true)}
       style={{ verticalAlign: "bottom" }}
      >
       Cancel
      </button>
     )}
     {!isDisabled && (
      <>
       <h5>Upload Profile Picture</h5>
       <input type="file" onChange={handleFileChange} />
       <button className="button" onClick={handleUpload} disabled={loading}>
        Upload
       </button>
      </>
     )}
    </div>
   }
  </div>
 );
};

export default UploadProfilePicture;
