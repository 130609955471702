import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "./components/footer";
import SideNavigationBar from "./components/sideNavigationBar";
import { useSelector } from "react-redux";
import HomeView from "../src/screens/homeView";
import AddEmployeeView from "./screens/addEmployeeView";
import AnalysisView from "./screens/analysisView";
import LoginView from "./screens/Authentication Screens/LoginView";
import RegisterView from "./screens/Authentication Screens/RegisterView";
import EmployeeDetailsView from "./screens/employeeDetailsView";
import AttendanceView from "./screens/attendanceView";
import ProfileView from "./screens/profileView";
import UserListView from "./screens/userListView";
import UserEditView from "./screens/userEditView";
import EmployeeListView from "./screens/employeeListView";
import SettingsView from "./screens/settings";
import BpjsView from "./screens/bpjsListView";
import AddAttendanceView from "./screens/addAttendanceView";

const App = () => {
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 return (
  <Router>
   <div style={{ display: "flex" }}>
    <SideNavigationBar />
    <main
     className="py-3"
     style={{ flex: 1, paddingLeft: userInfo ? "10px" : "0px" }}
    >
     <Container>
      <Route path="/user/:userId/edit" component={UserEditView} />
      <Route path="/userList" component={UserListView} />
      <Route path="/profile" component={ProfileView} />
      <Route path="/attendanceLama" component={AttendanceView} />
      <Route path="/analysis" component={AnalysisView} />
      <Route path="/addEmployee" component={AddEmployeeView} />
      <Route path="/employee/:id" component={EmployeeDetailsView} exact />
      <Route path="/login" component={LoginView} exact />
      <Route path="/register" component={RegisterView} exact />
      <Route path="/page/:pageNumber" component={EmployeeListView} exact />
      <Route
       path="/search/:keyword/page/:pageNumber"
       component={HomeView}
       exact
      />
      <Route path="/" component={HomeView} exact />
      <Route path="/employeeList" component={EmployeeListView} exact />
      <Route path="/employeeList/:keyword" component={EmployeeListView} exact />
      <Route path="/settings" component={SettingsView} exact />
      <Route path="/bpjsList" component={BpjsView} exact />
      {/* <Route path="/attendance/:id" component={AttendanceDetailsView} exact />*/}
      <Route path="/attendance" component={AddAttendanceView} exact />
      <Route path="/attendance/:keyword" component={AddAttendanceView} exact />
     </Container>
    </main>
   </div>
   <Footer />
  </Router>
 );
};

export default App;
