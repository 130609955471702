import React from "react";
import { useHistory } from "react-router-dom";

const GoBackButton = ({ text = `Go Back` }) => {
 const history = useHistory();

 const goBack = () => {
  history.goBack(); // This function will take you back to the previous page in history
 };

 return (
  <div style={{ paddingLeft: "15px" }}>
   <button onClick={goBack} className="button" style={{ borderRadius: "8px" }}>
    {text}
   </button>
  </div>
 );
};

export default GoBackButton;
