import React, { useState } from "react";
import { Form } from "react-bootstrap";

const SearchBox = ({ pageName, history, keyword, outlet }) => {
 const [key, setKey] = useState(keyword);

 // Fungsi submit handler untuk form search
 const submitHandler = (e) => {
  e.preventDefault(); // Mencegah reload halaman
  if (key.trim()) {
   history.push(
    outlet ? `/${pageName}/${key}?outlet=${outlet}` : `/${pageName}/${key}`
   ); // Mengarahkan ke halaman dengan keyword
  }
 };

 // Fungsi untuk menghapus search
 const clearSearch = () => {
  setKey(""); // Kosongkan input search
  history.push(outlet ? `/${pageName}?outlet=${outlet}` : `/${pageName}`); // Kembali ke halaman utama
 };

 return (
  <Form onSubmit={submitHandler} style={{ justifyContent: "right" }} inline>
   <div style={{ display: "flex", alignItems: "center" }}>
    <div
     style={{ position: "relative", display: "flex", alignItems: "center" }}
    >
     <Form.Control
      type="text"
      value={key} // Menggunakan state key
      name="q"
      placeholder="Search by name..."
      onChange={(e) => setKey(e.target.value)} // Set key saat input berubah
      style={{
       backgroundColor: "rgba(50,50,50,0.1)",
       borderRadius: "8px",
       paddingRight: "2.5em", // Ruang untuk ikon x
       width: "200px",
      }}
     />
     {key && (
      <button
       type="button"
       className="clear-button"
       onClick={clearSearch} // Menghapus keyword dan kembali ke halaman utama
       style={{
        position: "absolute",
        right: "10px", // Posisikan di sebelah kanan input
        background: "transparent",
        border: "none",
        fontSize: "1.5em",
        cursor: "pointer",
       }}
      >
       &times;
      </button>
     )}
    </div>
    <button
     type="submit"
     className="button"
     style={{
      height: "3.3em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
     }}
    >
     <i className="fas fa-search" style={{ fontSize: "1.25em" }} />
    </button>
   </div>
  </Form>
 );
};

export default SearchBox;
