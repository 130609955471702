import {
 Container,
 Sidebar as RSuiteSidebar, // Renamed here
 Sidenav,
 Nav,
 IconButton,
 Stack,
 HStack,
} from "rsuite";
import React, { useState, useEffect } from "react";
import { Icon } from "@rsuite/icons";
import {
 MdPersonAdd,
 MdHome,
 MdLogout,
 MdGroup,
 MdSettings,
 MdKeyboardArrowLeft,
 MdOutlineKeyboardArrowRight,
 MdAvTimer,
 MdAlarmAdd,
} from "react-icons/md";
import logoImage from "../Assets/Image/LogoFullColour.png";
import { Image, NavLink } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../actions/userActions";

const SideNavigationBar = () => {
 const [expand, setExpand] = useState(true);
 const dispatch = useDispatch();
 const logoutHandler = () => {
  dispatch(logout());
 };
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;
 const userDetails = useSelector((state) => state.userDetails);
 const { loading, user } = userDetails;
 useEffect(() => {
  if (!user || !user.name) {
   dispatch(getUserDetails("profile"));
  }
 }, [dispatch, loading, userInfo, user]);

 if (!userInfo || !user) {
  return <></>;
 }

 return (
  <div
   height={800}
   className="sidebar-page"
   style={{
    position: "relative",
    width: expand ? 200 : 56,
    height: "100vh",
    zIndex: 999,
   }}
  >
   <Container>
    <RSuiteSidebar
     style={{
      display: "flex",
      flexDirection: "column",
      top: 0,
      height: "100%",
      position: "fixed",
      borderRight: "1px solid black", // Tambahkan garis di pinggir kanan
      backgroundColor: "#ffffff",
      boxShadow: "0 4px 2px -2px gray" /* Tambahkan bayangan untuk estetika */,
     }}
     width={expand ? 225 : 56}
     collapsible
    >
     <Sidenav.Header>
      <Logo expand={expand} />
     </Sidenav.Header>
     <Sidenav expanded={expand} defaultOpenKeys={["1"]} appearance="subtle">
      <Sidenav.Body color="white">
       <Nav defaultActiveKey="1">
        <LinkContainer to="/">
         <Nav.Item eventKey="1" icon={<Icon as={MdHome} />}>
          Home
         </Nav.Item>
        </LinkContainer>

        <Nav.Menu
         eventKey="2"
         trigger="hover"
         title="Employee"
         icon={<Icon as={MdGroup} />}
         placement="rightStart"
        >
         <LinkContainer to="/employeeList">
          <Nav.Item eventKey="3-1" icon={<Icon as={MdGroup} />}>
           {" Employee List"}
          </Nav.Item>
         </LinkContainer>
         {user.isAdmin && (
          <LinkContainer to="/addEmployee">
           <Nav.Item eventKey="3-2" icon={<Icon as={MdPersonAdd} />}>
            {" Add Employee"}
           </Nav.Item>
          </LinkContainer>
         )}
        </Nav.Menu>
        <Nav.Menu
         eventKey="3"
         trigger="hover"
         title="Attendance"
         icon={<Icon as={MdAvTimer} />}
         placement="rightStart"
        >
         <LinkContainer to="/attendance">
          <Nav.Item eventKey="3-2" icon={<Icon as={MdAlarmAdd} />}>
           {" Attendance List"}
          </Nav.Item>
         </LinkContainer>
        </Nav.Menu>
        {user.isAdmin && (
         <LinkContainer to="/userList">
          <Nav.Item eventKey="4" icon={<Icon as={MdGroup} />}>
           {" User List"}
          </Nav.Item>
         </LinkContainer>
        )}
        <LinkContainer to="/profile">
         <NavLink>
          {user ? (
           <img
            src={
             user && user.profilePicture
              ? `../${user.profilePicture}`
              : user &&
                !user.profilePicture &&
                `../uploads/images/profilePictures/blank-profile-picture.png`
            }
            alt="Profile"
            style={{
             width: "30px",
             height: "30px",
             borderRadius: "50%",
            }}
           />
          ) : (
           <i className="fas fa-user-circle" style={{ fontSize: "1.75em" }} />
          )}
          <span style={{ paddingLeft: "10px" }}></span>
          {expand && userInfo && userInfo.name ? userInfo.name : <></>}
         </NavLink>
        </LinkContainer>
        {user.isAdmin && (
         <LinkContainer to="/settings">
          <Nav.Item eventKey="6" icon={<Icon as={MdSettings} />}>
           {" Settings"}
          </Nav.Item>
         </LinkContainer>
        )}

        {expand && (
         <Nav.Item
          eventKey="7"
          icon={<Icon as={MdLogout} />}
          onClick={logoutHandler}
          style={{ color: "red" }}
         >
          Sign out
         </Nav.Item>
        )}
       </Nav>
      </Sidenav.Body>
     </Sidenav>
     <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
    </RSuiteSidebar>
   </Container>
  </div>
 );
};

const NavToggle = ({ expand, onChange }) => {
 return (
  <Stack className="nav-toggle" justifyContent={expand ? "flex-end" : "center"}>
   <IconButton
    onClick={onChange}
    appearance="subtle"
    size="lg"
    icon={expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />}
   />
  </Stack>
 );
};

const Logo = ({ children, expand }) => {
 return (
  <HStack className="page-brand" spacing={12} justifyContent="center">
   <Image
    src={logoImage}
    alt="Logo"
    style={
     expand
      ? { width: "125px", height: "60px" }
      : { width: "40px", height: "20px" }
    }
    justifyContent="center"
   />
  </HStack>
 );
};

export default SideNavigationBar;
