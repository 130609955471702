import {
 ATTENDANCE_SUMMARY_DATA_ENTER_FAIL,
 ATTENDANCE_SUMMARY_DATA_ENTER_REQUEST,
 ATTENDANCE_SUMMARY_DATA_ENTER_RESET,
 ATTENDANCE_SUMMARY_DATA_ENTER_SUCCESS,
 ATTENDANCE_SUMMARY_ANALYSIS_FAIL,
 ATTENDANCE_SUMMARY_ANALYSIS_REQUEST,
 ATTENDANCE_SUMMARY_ANALYSIS_RESET,
 ATTENDANCE_SUMMARY_ANALYSIS_SUCCESS,
 ATTENDANCE_SUMMARY_DELETE_REQUEST,
 ATTENDANCE_SUMMARY_DELETE_SUCCESS,
 ATTENDANCE_SUMMARY_DELETE_FAIL,
 ATTENDANCE_SUMMARY_DETAILS_REQUEST,
 ATTENDANCE_SUMMARY_DETAILS_SUCCESS,
 ATTENDANCE_SUMMARY_DETAILS_ERROR,
 ATTENDANCE_SUMMARY_UPDATE_REQUEST,
 ATTENDANCE_SUMMARY_UPDATE_SUCCESS,
 ATTENDANCE_SUMMARY_UPDATE_ERROR,
 ATTENDANCE_SUMMARY_UPDATE_RESET,
} from "../constants/attendanceSummaryConstant";

export const attendanceSummaryDataEnterReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_SUMMARY_DATA_ENTER_REQUEST:
   return { ...state, loading: true };
  case ATTENDANCE_SUMMARY_DATA_ENTER_SUCCESS:
   return {
    loading: false,
    attendanceSummary: action.payload,
   };
  case ATTENDANCE_SUMMARY_DATA_ENTER_FAIL:
   return { loading: false, error: action.payload };
  case ATTENDANCE_SUMMARY_DATA_ENTER_RESET:
   return {};

  default:
   return state;
 }
};

export const attendanceSummaryAnalysisReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_SUMMARY_ANALYSIS_REQUEST:
   return { loading: true };
  case ATTENDANCE_SUMMARY_ANALYSIS_SUCCESS:
   return {
    loading: false,
    attendanceSummary: action.payload,
   };
  case ATTENDANCE_SUMMARY_ANALYSIS_FAIL:
   return { loading: false, error: action.payload };
  case ATTENDANCE_SUMMARY_ANALYSIS_RESET:
   return {};

  default:
   return state;
 }
};

export const deleteAttendanceSummaryReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_SUMMARY_DELETE_REQUEST:
   return { loading: true };
  case ATTENDANCE_SUMMARY_DELETE_SUCCESS:
   return {
    loading: false,
    success: true,
   };
  case ATTENDANCE_SUMMARY_DELETE_FAIL:
   return { loading: false, error: action.payload };
  case ATTENDANCE_SUMMARY_ANALYSIS_RESET:
   return {};

  default:
   return state;
 }
};

export const attendanceSummaryDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_SUMMARY_DETAILS_REQUEST:
   return { ...state, loading: true };
  case ATTENDANCE_SUMMARY_DETAILS_SUCCESS:
   return { loading: false, attendanceSummary: action.payload };
  case ATTENDANCE_SUMMARY_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const attendanceSummaryUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_SUMMARY_UPDATE_REQUEST:
   return { ...state, loading: true };
  case ATTENDANCE_SUMMARY_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case ATTENDANCE_SUMMARY_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case ATTENDANCE_SUMMARY_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};
