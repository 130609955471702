import { React } from "react";

// Komponen untuk menampilkan link email
const EmailLink = ({ email, color = "#D93025" }) => {
 const mailtoUrl = `mailto:${email}`;

 return (
  <a
   href={mailtoUrl}
   target="_blank"
   rel="noopener noreferrer"
   style={{ textDecoration: "none", color: "inherit" }}
  >
   <i
    className="fas fa-envelope"
    style={{ fontSize: "1.75em", color: color }}
   />
  </a>
 );
};

export { EmailLink };
