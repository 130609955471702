import React from "react";

const FacebookLink = () => {
 return (
  <a
   href="https://www.facebook.com/sinarutama.id/"
   target="_blank"
   rel="noopener noreferrer"
  >
   <i className="fab fa-facebook-square" style={{ fontSize: "1.75em" }} />
  </a>
 );
};

export default FacebookLink;
