import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { getAge, getWorkDuration } from "../utils/utils";
import { addEmployee } from "../actions/employeeActions";
import { options } from "../utils/utils";
import Loading from "../components/loader";
import Message from "../components/message.jsx";

const AddEmployeeView = ({ match, history }) => {
 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [outlet, setOutlet] = useState("");
 const [ktpID, setKtpID] = useState("");
 const [addressOfKTP, setAddressOfKTP] = useState("");
 const [address, setAddress] = useState("");

 const [status, setStatus] = useState("");
 const [position, setPosition] = useState("");
 const [sex, setSex] = useState("");
 const [bpjsKesehatan, setBpjsKesehatan] = useState("");
 const [bpjsKesehatanDescription, setBpjsKesehatanDescription] = useState("");
 const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState("");
 const [bpjsKetenagakerjaanDescription, setBpjsKetenagakerjaanDescription] =
  useState("");

 const [placeOfBirth, setPlaceOfBirth] = useState("");
 const [dateOfBirth, setDateOfBirth] = useState(null);
 const [dateIn, setDateIn] = useState(null);
 const [dateResign, setDateResign] = useState(null);
 const [phone, setPhone] = useState("");
 const [email, setEmail] = useState("");

 const dispatch = useDispatch();
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;
 const employeeAdd = useSelector((state) => state.employeeAdd);
 const { loading, error, success } = employeeAdd;

 useEffect(() => {
  if (!loading && !userInfo) {
   history.push("/login");
  }
  if (!userInfo.isAdmin) {
   history.push("/");
  }
  if (success) {
   history.push("/employeeList");
  }
 }, [dispatch, history, success, userInfo, loading]);

 const submitHandler = () => {
  dispatch(
   addEmployee({
    employeeID,
    name,
    outlet,
    ktpID,
    addressOfKTP,
    address,
    status,
    position,
    sex,
    bpjsKesehatan,
    bpjsKesehatanDescription,
    bpjsKetenagakerjaan,
    bpjsKetenagakerjaanDescription,
    placeOfBirth,
    dateOfBirth,
    dateIn,
    dateResign,
    phone,
    email,
   })
  );
 };

 const defaultOptions = "Select...";
 const sexOptions = options([defaultOptions, "Men", "Women"]);
 const statusOptions = options([defaultOptions, "TETAP", "PROBATION"]);
 const outletOptions = options([
  defaultOptions,
  "SRIWIJAYA",
  "SUDIRMAN",
  "SETIABUDI",
  "MAHAKAM",
  "MANAGEMENT",
  "KHALID MEDAN",
  "KHALID BINTARO",
 ]);

 // Fungsi untuk mengubah tanggal menjadi format YYYY-MM-DD
 const dateForPicker = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dateString ? dayjs(dateString).toDate() : null;
 };

 const getAgeLong = () => {
  return getAge(dateOfBirth);
 };

 return (
  <>
   {loading ? (
    <Loading />
   ) : (
    <>
     {error && <Message variant="danger">{error}</Message>}
     <Row>
      <Col md={4}>
       <GoBackButton onClick={() => history.goBack()}></GoBackButton>
      </Col>
      <Col md={4}>
       <h3>{`Add Employee`}</h3>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <h4 className="underline">Work</h4>
      </Col>
     </Row>

     <Row>
      <Col md={4}>
       <Form.Group controlId="employeeID">
        <Form.Label>
         Employee ID<span>*</span>
        </Form.Label>
        <Form.Control
         placeholder="Enter employee ID"
         value={employeeID}
         onChange={(e) => setEmployeeID(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="status">
        <Form.Label>
         Status<span>*</span>
        </Form.Label>
        <Form.Control
         as="select"
         placeholder=""
         value={status}
         onChange={(e) => setStatus(e.target.value)}
        >
         {statusOptions}
        </Form.Control>
       </Form.Group>
       <Form.Group controlId="bpjsKesehatan">
        <Form.Label>BPJS Kesehatan</Form.Label>
        <Form.Control
         placeholder="Enter BPJS Kesehatan"
         value={bpjsKesehatan}
         onChange={(e) => setBpjsKesehatan(e.target.value)}
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="bpjsKetenagakerjaan">
        <Form.Label>BPJS Ketenagakerjaan</Form.Label>
        <Form.Control
         placeholder="Enter BPJS Ketenagakerjaan"
         value={bpjsKetenagakerjaan}
         onChange={(e) => setBpjsKetenagakerjaan(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="name">
        <Form.Label>
         Name<span>*</span>
        </Form.Label>
        <Form.Control
         placeholder="Enter full name"
         value={name}
         onChange={(e) => setName(e.target.value)}
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="position">
        <Form.Label>
         Position<span>*</span>
        </Form.Label>
        <Form.Control
         placeholder="Enter position"
         value={position}
         onChange={(e) => setPosition(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="bpjsKesehatanDescription">
        <Form.Label>BPJS Kesehatan Description</Form.Label>
        <Form.Control
         placeholder="Enter BPJS Kesehatan Description"
         value={bpjsKesehatanDescription}
         onChange={(e) => setBpjsKesehatanDescription(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="bpjsKetenagakerjaanDescription">
        <Form.Label>BPJS Ketenagakerjaan Description</Form.Label>
        <Form.Control
         placeholder="Enter BPJS Ketenagakerjaan Description"
         value={bpjsKetenagakerjaanDescription}
         onChange={(e) => setBpjsKetenagakerjaanDescription(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="outlet">
        <Form.Label>
         Outlet<span>*</span>
        </Form.Label>
        <Form.Control
         as="select"
         placeholder=""
         value={outlet}
         onChange={(e) => setOutlet(e.target.value)}
        >
         {outletOptions}
        </Form.Control>
       </Form.Group>
       <Form.Group controlId="dateIn">
        <Row>
         <Col>
          <Form.Label>
           Date Join<span>*</span>
          </Form.Label>
         </Col>
         <Col md={9}>
          <Form.Text className="text-muted">
           {dateIn && `Duration: ${getWorkDuration(dateIn, dateResign)}`}
          </Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateIn)}
         onChange={(e) => setDateIn(dateFromDateString(e.target.value))}
        />
       </Form.Group>

       <Form.Group controlId="dateResign">
        <Row>
         <Col>
          <Form.Label>Date Resign</Form.Label>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateResign)}
         onChange={(e) => setDateResign(dateFromDateString(e.target.value))}
        />
       </Form.Group>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <h4 className="underline">Personal Data</h4>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <Form.Group controlId="ktpID">
        <Form.Label>KTP</Form.Label>
        <Form.Control
         placeholder="Enter KTP ID"
         value={ktpID}
         onChange={(e) => setKtpID(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="placeOfBirth">
        <Form.Label>Place of Birth</Form.Label>
        <Form.Control
         placeholder="Enter place of birth"
         value={placeOfBirth}
         onChange={(e) => setPlaceOfBirth(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="addressOfKTP">
        <Form.Label>Address of KTP</Form.Label>
        <Form.Control
         placeholder="Enter address of KTP"
         value={addressOfKTP}
         onChange={(e) => setAddressOfKTP(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="dateOfBirth">
        <Row>
         <Col>
          <Form.Label>Date of Birth </Form.Label>
         </Col>
         <Col md={8}>
          <Form.Text className="text-muted">{getAgeLong()}</Form.Text>
         </Col>
        </Row>
        <Form.Control
         type="date"
         value={dateForPicker(dateOfBirth)}
         onChange={(e) => setDateOfBirth(dateFromDateString(e.target.value))}
        />
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="address">
        <Form.Label>Address of Domicile</Form.Label>
        <Form.Control
         placeholder="Enter address of domicile"
         value={address}
         onChange={(e) => setAddress(e.target.value)}
        ></Form.Control>
       </Form.Group>
       <Form.Group controlId="sex">
        <Form.Label>
         Sex<span>*</span>
        </Form.Label>
        <Form.Control
         as="select"
         placeholder=""
         value={sex}
         onChange={(e) => setSex(e.target.value)}
        >
         {sexOptions}
        </Form.Control>
       </Form.Group>
      </Col>
     </Row>
     <Row>
      <Col md={3}>
       <h4 className="underline">Contact</h4>
      </Col>
     </Row>
     <Row>
      <Col md={4}>
       <Form.Group controlId="phone">
        <Row>
         <Col>
          <Form.Label>Phone</Form.Label>
         </Col>
        </Row>
        <Form.Control
         placeholder="Enter phone"
         value={phone}
         onChange={(e) => setPhone(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}>
       <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
         placeholder="Enter email"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
        ></Form.Control>
       </Form.Group>
      </Col>
      <Col md={4}></Col>
     </Row>
     <Row>
      <Col md={3}>
       {
        <button
         type="submit"
         className="button"
         onClick={() => {
          submitHandler();
         }}
        >
         Add Employee
        </button>
       }
      </Col>
     </Row>
    </>
   )}
  </>
 );
};

export default AddEmployeeView;
