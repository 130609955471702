import {
 ATTENDANCE_DATA_ENTER_FAIL,
 ATTENDANCE_DATA_ENTER_REQUEST,
 ATTENDANCE_DATA_ENTER_PROGRESS,
 ATTENDANCE_DATA_ENTER_SUCCESS,
 ATTENDANCE_DATA_ENTER_COMPLETE,
 ATTENDANCE_ANALYSIS_FAIL,
 ATTENDANCE_ANALYSIS_REQUEST,
 ATTENDANCE_ANALYSIS_RESET,
 ATTENDANCE_ANALYSIS_SUCCESS,
 ATTENDANCE_DELETE_REQUEST,
 ATTENDANCE_DELETE_SUCCESS,
 ATTENDANCE_DELETE_FAIL,
 ATTENDANCE_DETAILS_REQUEST,
 ATTENDANCE_DETAILS_SUCCESS,
 ATTENDANCE_DETAILS_ERROR,
 ATTENDANCE_UPDATE_REQUEST,
 ATTENDANCE_UPDATE_SUCCESS,
 ATTENDANCE_UPDATE_ERROR,
 ATTENDANCE_UPDATE_RESET,
} from "../constants/attendanceConstant";

export const attendanceDataEnterReducer = (state = { progress: 0 }, action) => {
 switch (action.type) {
  case ATTENDANCE_DATA_ENTER_REQUEST:
   return { ...state, loading: true, progress: 0 };
  case ATTENDANCE_DATA_ENTER_PROGRESS:
   return { ...state, progress: action.payload };
  case ATTENDANCE_DATA_ENTER_SUCCESS:
   return { ...state, loading: false, success: true };
  case ATTENDANCE_DATA_ENTER_COMPLETE:
   return { ...state, loading: false, success: true, progress: 100 };
  case ATTENDANCE_DATA_ENTER_FAIL:
   return { ...state, loading: false, error: action.payload };
  default:
   return state;
 }
};

export const attendanceAnalysisReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_ANALYSIS_REQUEST:
   return { loading: true };
  case ATTENDANCE_ANALYSIS_SUCCESS:
   return {
    loading: false,
    attendance: action.payload,
   };
  case ATTENDANCE_ANALYSIS_FAIL:
   return { loading: false, error: action.payload };
  case ATTENDANCE_ANALYSIS_RESET:
   return {};

  default:
   return state;
 }
};

export const deleteAttendanceReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_DELETE_REQUEST:
   return { loading: true };
  case ATTENDANCE_DELETE_SUCCESS:
   return {
    loading: false,
    success: true,
   };
  case ATTENDANCE_DELETE_FAIL:
   return { loading: false, error: action.payload };
  case ATTENDANCE_ANALYSIS_RESET:
   return {};

  default:
   return state;
 }
};

export const attendanceDetailsReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_DETAILS_REQUEST:
   return { ...state, loading: true };
  case ATTENDANCE_DETAILS_SUCCESS:
   return { loading: false, attendance: action.payload };
  case ATTENDANCE_DETAILS_ERROR:
   return { loading: false, error: action.payload };

  default:
   return state;
 }
};

export const attendanceUpdateReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_UPDATE_REQUEST:
   return { ...state, loading: true };
  case ATTENDANCE_UPDATE_SUCCESS:
   return { loading: false, success: true };
  case ATTENDANCE_UPDATE_ERROR:
   return { loading: false, error: action.payload };
  case ATTENDANCE_UPDATE_RESET:
   return {};

  default:
   return state;
 }
};

export const attendanceFileUploadReducer = (state = {}, action) => {
 switch (action.type) {
  case ATTENDANCE_DATA_ENTER_REQUEST:
   return { ...state, isUploadingAttendanceFile: true };
  case ATTENDANCE_DATA_ENTER_SUCCESS:
   return {
    isUploadingAttendanceFile: false,
    isUploadingAttendanceFileFinished: action.payload,
   };
  case ATTENDANCE_DATA_ENTER_FAIL:
   return {
    isUploadingAttendanceFile: false,
    errorUploadingAttendanceFile: action.payload,
   };

  default:
   return state;
 }
};
