import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { NavLink, Modal, Form, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { capitalizeEachWord, convertSeconds } from "../utils/utils";
import { TooltipButton } from "../components/tooltip";
import { options } from "../utils/utils.jsx";
import { useDispatch, useSelector } from "react-redux";
import { addAttendance, deleteAttendance } from "../actions/attendanceActions";
import { getAnalysisAttendanceSummaryByDate } from "../actions/attendanceSummaryActions.jsx";

const AddAttendanceTableView = ({
 keyword,
 isActiveEmployee,
 outlet,
 date,
 details,
 userInfo,
}) => {
 // State untuk mengontrol visibilitas popup
 const [showAddAttendance, setShowAddAttendance] = useState(false);
 const [addAttendanceItem, setAddAttendanceItem] = useState({});
 const [addAttendanceDate, setAddAttendanceDate] = useState(null);
 const [addAttendanceStatus, setAddAttendanceStatus] = useState(null);
 const [addAttendanceClockIn, setAddAttendanceClockIn] = useState(null);
 const [addAttendanceClockOut, setAddAttendanceClockOut] = useState(null);
 const [addAttendanceOvertimeHours, setAddAttendanceOvertimeHours] =
  useState(null);
 const [addAttendanceOvertimeType, setAddAttendanceOvertimeType] =
  useState("normal");
 const [addAttendanceData, setAddAttendanceData] = useState(null);
 const [addAttendanceId, setAddAttendanceId] = useState(null);

 const dispatch = useDispatch();
 const attendanceSummaryAnalysis = useSelector(
  (state) => state.attendanceSummaryAnalysis
 );
 const { attendanceSummary } = attendanceSummaryAnalysis;
 // Function to find matching attendance data by date
 const matchingDates = () => {
  if (!addAttendanceDate || !Array.isArray(addAttendanceItem.attendanceData)) {
   setAddAttendanceData(null);
   return;
  }
  const targetDate = dayjs(addAttendanceDate);

  const attendanceData = addAttendanceItem.attendanceData.find(
   (item) =>
    dayjs(item.date).format("YYYY-MM-DD") === targetDate.format("YYYY-MM-DD")
  );
  setAddAttendanceData(attendanceData || null);
 };

 useEffect(
  () => {
   if (addAttendanceDate && showAddAttendance) {
    matchingDates();
    if (addAttendanceData) {
     if (addAttendanceData.clockIn && addAttendanceData.clockOut) {
      setAddAttendanceClockIn(dayjs(addAttendanceData.clockIn).format("HH:mm"));
      setAddAttendanceClockOut(
       dayjs(addAttendanceData.clockOut).format("HH:mm")
      );
      setAddAttendanceOvertimeHours(addAttendanceData.overtimeHours);
      setAddAttendanceOvertimeType(addAttendanceData.overtimeType);
     } else {
      setAddAttendanceClockIn(null);
      setAddAttendanceClockOut(null);
     }
     setAddAttendanceStatus(addAttendanceData.status);
     setAddAttendanceId(addAttendanceData._id);
    } else {
     // Reset all fields if no data is found
     setAddAttendanceClockIn(null);
     setAddAttendanceClockOut(null);
     setAddAttendanceOvertimeHours(null);
     setAddAttendanceOvertimeType("normal");
     setAddAttendanceStatus(null);
     setAddAttendanceId(null);
    }
   }
  },
  // eslint-disable-next-line
  [addAttendanceDate, showAddAttendance, addAttendanceData, attendanceSummary]
 );

 // Cegah refresh saat submit form
 const handleSubmit = (event) => {
  if (event) {
   event.preventDefault(); // Mencegah halaman refresh
  }
  console.log({ addAttendanceData });
  // Implementasi logika submit di sini tanpa refresh halaman
  //console.log({
  // addAttendanceItem,
  // addAttendanceDate,
  // addAttendanceStatus,
  // addAttendanceClockIn,
  // addAttendanceClockOut,
  //});

  dispatch(
   addAttendance({
    _id: addAttendanceItem._id,
    employeeID: addAttendanceItem.employeeID,
    date: addAttendanceDate,
    clockIn: addAttendanceClockIn,
    clockOut: addAttendanceClockOut,
    status: addAttendanceStatus,
    overtimeHours: addAttendanceOvertimeHours,
    overtimeType: addAttendanceOvertimeType,
    attendanceId: addAttendanceId,
   })
  );

  // Close modal setelah submit
  handleCloseAddAttendance();
  dispatch(
   getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
  );
 };

 const deleteAttendanceHandler = () => {
  if (window.confirm("Are you sure")) {
   dispatch(deleteAttendance(addAttendanceId));
   //_id, attendanceId, employeeID, date
   // Close modal setelah delete
   handleCloseAddAttendance();
   dispatch(
    getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
   );
  }
 };

 // Fungsi untuk membuka dan menutup popup
 const handleCloseAddAttendance = (event) => {
  if (event) {
   event.preventDefault();
  }

  setShowAddAttendance(false);
  setAddAttendanceItem({});
  setAddAttendanceDate(null);
  setAddAttendanceStatus(null);
  setAddAttendanceClockIn(null);
  setAddAttendanceClockOut(null);
  setAddAttendanceData(null);
 };
 const handleShowAddAttendance = (event, item) => {
  if (event) {
   event.preventDefault(); // Prevent the default action
  }

  setShowAddAttendance(true);
  setAddAttendanceItem(item);
 };
 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems = items?.length ? [...items] : items;

   if (sortConfig !== null && items?.length) {
    sortableItems.sort((a, b) => {
     if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
     }
     if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
     }
     return 0;
    });
   }

   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };
 const dateForPicker = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dateString ? dayjs(dateString).toDate() : null;
 };
 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // Membuat array tanggal dari 26 Juli 2024 sampai 25 Agustus 2024
  const startDate = dayjs(date);
  const endDate = dayjs(startDate).add(1, "month").subtract(1, "day");
  const totalDays = endDate.diff(startDate, "day") + 1;
  const dateRange = [];

  //console.log(`startDate: ${startDate.format("DD/MM/YYYY")}`);
  //console.log(`endDate: ${endDate.format("DD/MM/YYYY")}`);

  for (
   let date = startDate;
   date.isBefore(endDate) || date.isSame(endDate);
   date = date.add(1, "day")
  ) {
   dateRange.push(date);
  }

  return (
   <>
    <table striped bordered hover responsive>
     <thead
      style={{ position: "sticky", top: 0, border: "1px solid lightgray" }}
     >
      <tr>
       <th
        style={{
         textAlign: "center",
         padding: "0.5px",
         minWidth: "330px",
         border: "1px solid lightgray",
        }}
       >
        <button
         type="button"
         onClick={() => requestSort("name")}
         className={getClassNamesFor("name")}
        >
         NAME
        </button>
       </th>
       {/* Generate columns for dates */}
       {dateRange.map((date) => (
        <th
         key={date.format("YYYY-MM-DD")}
         style={{
          textAlign: "center",
          border: "1px solid lightgray",
         }}
        >
         <span style={{ fontSize: "8px", fontWeight: "normal" }}>
          {date.format("MMM")}
         </span>
         <br />
         {date.format("DD")}
         <br />
         <span style={{ fontSize: "8px", fontWeight: "normal" }}>
          {capitalizeEachWord(date.format("ddd"))}
         </span>
        </th>
       ))}
      </tr>
     </thead>
     <tbody style={{ color: "#000000" }}>
      {items &&
       items
        .filter((item) => {
         if (isActiveEmployee) {
          return !item.dateResign; // Show only employees who have not resigned
         } else {
          return true; // Show only employees who have resigned
         }
        })
        .map((item) => (
         <tr
          key={item._id}
          style={{
           border: "1px solid lightgray",
           maxHeight: "10px", // Set maximum height for the row
           overflow: "hidden", // Hide overflow content
           verticalAlign: "middle", // Vertically align the content
          }}
         >
          <td
           style={{
            minWidth: "200px",
            overflowWrap: "break-word",
            lineHeight: "10px", // Adjust line height to fit the 20px height
            height: "10px", // Set fixed height for the cells
            padding: "0px", // Remove padding to fit the max height
            verticalAlign: "middle", // Vertically align the content
           }}
          >
           <LinkContainer to={`/employee/${item._id}`}>
            <NavLink>
             <div
              style={{
               display: "flex",
               justifyContent: "space-between",
               gap: "10px", // optional, to add space between the image and the name
               textAlign: "center", // Horizontal centering
               verticalAlign: "middle", // Vertical centering
              }}
             >
              <img
               src={
                item.profilePicture
                 ? `../${item.profilePicture}`
                 : `../uploads/images/profilePictures/blank-profile-picture.png`
               }
               alt="Profile"
               style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
               }}
              />
              <span
               style={{
                flexGrow: 1,
                textAlign: "left",
                fontSize: "12px",
                verticalAlign: "middle", // Vertical centering
                fontWeight: "400",
               }}
              >
               {`${capitalizeEachWord(item.name)} `}
               {typeof item.status === "string" &&
                item.status.toLowerCase().includes("tetap") && (
                 <i
                  className="fas fa-check-circle"
                  style={{ color: "rgba(37, 211, 102, 1)" }}
                 ></i>
                )}
              </span>
              {item.totalSakit > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Sakit:${item.totalSakit}`}
               </button>
              )}
              {item.totalIzin > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Izin:${item.totalIzin}`}
               </button>
              )}

              {item.totalAbsen > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Absen:${item.totalAbsen}`}
               </button>
              )}
              {item.totalSakitNonSurat > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`SNS:${item.totalSakitNonSurat}`}
               </button>
              )}
              {item.totalCutiTahunan > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti:${item.totalCutiTahunan}`}
               </button>
              )}
              {item.totalCutiMelahirkan > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti Melahirkan:${item.totalCutiMelahirkan}`}
               </button>
              )}
              {item.totalCutiIstriMelahirkan > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti Istri Melahirkan:${item.totalCutiIstriMelahirkan}`}
               </button>
              )}
              {item.totalCutiKeluargaMeninggal > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti Keluarga Meninggal:${item.totalCutiKeluargaMeninggal}`}
               </button>
              )}
              <button>
               <span style={{ fontSize: "10px" }}>
                {`${item.totalPresent}/${totalDays}`}
               </span>
              </button>
              {userInfo.isAdmin && (
               <button
                onClick={(event) => handleShowAddAttendance(event, item)}
                className="button"
                style={{
                 padding: "5px 10px",
                }}
               >
                <span style={{ fontSize: "10px" }}>{`+`}</span>
               </button>
              )}
             </div>
            </NavLink>
           </LinkContainer>
           <Row style={{ marginLeft: "15px" }}>
            {details && (
             <div>
              <button
               style={{
                verticalAlign: "middle", // Vertical centering
                borderRadius: "5px",
               }}
              >
               <span
                style={{
                 textAlign: "left",
                 fontSize: "8px",
                 verticalAlign: "middle", // Vertical centering
                }}
               >
                {capitalizeEachWord(item.position)}
               </span>
              </button>
             </div>
            )}
            {typeof item.status === "string" &&
             !item.status.toLowerCase().includes("tetap") && (
              <button
               style={{
                verticalAlign: "middle", // Vertical centering
                borderRadius: "5px",
                backgroundColor:
                 typeof item.status === "string" &&
                 item.status.toLowerCase().includes("tetap")
                  ? "rgb(169, 199, 169)"
                  : "rgb(168, 240, 245)",
                color:
                 typeof item.status === "string" &&
                 item.status.toLowerCase().includes("tetap")
                  ? "rgb(6, 106, 6)"
                  : "rgb(8, 48, 181)",
               }}
              >
               <span
                style={{
                 textAlign: "left",
                 fontSize: "8px",
                 verticalAlign: "middle", // Vertical centering
                }}
               >
                {capitalizeEachWord(item.status)}
               </span>
              </button>
             )}
           </Row>
          </td>
          {/* Generate columns for each date */}
          {dateRange.map((date) => {
           const dateString = date.format("YYYY-MM-DD");
           const attendance = item.attendanceData.find((entry) =>
            dayjs(entry.date).isSame(date, "day")
           );

           return (
            <td
             key={`${item._id}-${dateString}`}
             style={{
              border: "1px solid lightgray",
              maxWidth: "80px",
              textAlign: "center", // Horizontal centering
              verticalAlign: "middle", // Vertical centering
              justifyContent: "center",
              fontSize: "8px",
             }}
            >
             <TooltipButton
              text={
               attendance
                ? details
                  ? attendance.status === "hadir"
                    ? `${attendance.status}\ndate: ${dayjs(
                       attendance.date
                      ).format("DD-MMM-YYYY")}\nin: ${dayjs(
                       attendance.clockIn
                      ).format("HH:mm")}\nout: ${dayjs(
                       attendance.clockOut
                      ).format("HH:mm")}\novertime: ${
                       attendance.overtimeHours
                      }\novertime type: ${attendance.overtimeType}\nduration: ${
                       convertSeconds(attendance.workingDurationInSeconds).hours
                      } hours, ${
                       convertSeconds(attendance.workingDurationInSeconds)
                        .minutes
                      } minutes`
                    : `${attendance.status}\ndate: ${dayjs(
                       attendance.date
                      ).format("DD-MMM-YYYY")}`
                  : "H"
                : "-"
              }
              buttonText={
               attendance
                ? details
                  ? attendance.status === "hadir"
                    ? attendance.overtimeHours
                      ? `${dayjs(attendance.clockIn).format("HH:mm")}-${dayjs(
                         attendance.clockOut
                        ).format("HH:mm")} over:${attendance.overtimeHours}`
                      : `${dayjs(attendance.clockIn).format("HH:mm")}-${dayjs(
                         attendance.clockOut
                        ).format("HH:mm")}`
                    : `${attendance.status}`
                  : "H"
                : "-"
              }
              status={attendance && attendance.status}
             />
            </td>
           );
          })}
         </tr>
        ))}
     </tbody>
    </table>

    {/* Modal untuk menampilkan popup */}
    {userInfo.isAdmin && (
     <Modal
      show={showAddAttendance}
      onHide={(event) => handleCloseAddAttendance(event)}
     >
      <Modal.Header closeButton>
       <Modal.Title>Add Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {/* Form di dalam popup */}
       <Form onSubmit={handleSubmit}>
        <Row>
         <Col md="3">
          <Form.Group controlId="employeeID">
           <Form.Label>Employee ID</Form.Label>
           <Form.Control
            type="text"
            placeholder="employeeID"
            value={addAttendanceItem.employeeID}
            disabled
           />
          </Form.Group>
         </Col>
         <Col md="9">
          <Form.Group controlId="name">
           <Form.Label>Name</Form.Label>
           <Form.Control
            type="text"
            placeholder="name"
            value={addAttendanceItem.name}
            disabled
           />
          </Form.Group>
         </Col>
        </Row>

        <Row>
         <Col>
          <Form.Group controlId="date">
           <Form.Label>Date</Form.Label>
           <Form.Control
            type="date"
            value={dateForPicker(addAttendanceDate)}
            onChange={(e) => {
             setAddAttendanceDate(dateFromDateString(e.target.value));
             matchingDates();
            }}
           />
          </Form.Group>
         </Col>
         <Col>
          <Form.Group controlId="status">
           <Form.Label>Status</Form.Label>
           <Form.Control
            as="select"
            value={addAttendanceStatus}
            onChange={(e) => setAddAttendanceStatus(e.target.value)}
            style={{
             color: "white",
             backgroundColor: "#ff0000",
             borderRadius: "8px",
            }}
           >
            {options([
             "select...",
             "hadir",
             "sakit",
             "sakit non surat",
             "izin",
             "absen",
             "cuti tahunan",
             "cuti istri melahirkan",
             "cuti melahirkan",
             "cuti anggota keluarga satu rumah meninggal",
            ])}
           </Form.Control>
          </Form.Group>
         </Col>
        </Row>

        <Row>
         <Col>
          <Form.Group controlId="clockIn">
           <Form.Label>Clock In</Form.Label>
           <Form.Control
            type="time"
            value={addAttendanceClockIn}
            onChange={(e) => setAddAttendanceClockIn(e.target.value)}
           />
          </Form.Group>
         </Col>
         <Col>
          <Form.Group controlId="clockOut">
           <Form.Label>Clock Out</Form.Label>
           <Form.Control
            type="time"
            value={addAttendanceClockOut}
            onChange={(e) => setAddAttendanceClockOut(e.target.value)}
           />
          </Form.Group>
         </Col>
        </Row>

        {addAttendanceStatus === "hadir" && (
         <Row>
          <Col>
           <Form.Group controlId="overtimeHours">
            <Form.Label>Overtime Hours</Form.Label>
            <Form.Control
             type="number"
             placeholder="overtime hours"
             value={addAttendanceOvertimeHours}
             onChange={(e) => setAddAttendanceOvertimeHours(e.target.value)}
            />
           </Form.Group>
          </Col>
          <Col>
           <Form.Group controlId="overtimeType">
            <Form.Label>Overtime Type</Form.Label>
            <Form.Control
             as="select"
             value={addAttendanceOvertimeType}
             onChange={(e) => setAddAttendanceOvertimeType(e.target.value)}
             style={{
              color: "white",
              backgroundColor: "#ff0000",
              borderRadius: "8px",
             }}
            >
             {options(["normal", "longshift", "hari libur nasional"])}
            </Form.Control>
           </Form.Group>
          </Col>
         </Row>
        )}
       </Form>
      </Modal.Body>
      <Modal.Footer
       style={{ display: "flex", justifyContent: "space-between" }}
      >
       {addAttendanceData && (
        <button
         className="button"
         onClick={deleteAttendanceHandler}
         style={{ marginRight: "auto" }} // Agar tombol trash berada di paling kiri
        >
         <i className="fas fa-trash"></i>
        </button>
       )}

       <Button
        variant="secondary"
        onClick={(event) => handleCloseAddAttendance(event)}
       >
        Cancel
       </Button>
       <button
        className="button"
        type="submit"
        onClick={(event) => handleSubmit(event)}
       >
        Apply
       </button>
      </Modal.Footer>
     </Modal>
    )}
   </>
  );
 };

 return <>{<ProductTable products={attendanceSummary} />}</>;
};

export default AddAttendanceTableView;
