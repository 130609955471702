import dayjs from "dayjs";
const duration = require("dayjs/plugin/duration");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(duration);
dayjs.extend(relativeTime);

function getAxiosTargetPath(targetPath) {
 let axiosPath = targetPath;
 console.log(`GET ${axiosPath}`);
 return axiosPath;
}

function getAge(birthDate) {
 if (!dayjs(birthDate).isValid()) {
  return;
 }
 const now = dayjs();
 const birth = dayjs(birthDate) || dayjs("1950-01-01");
 const diff = now.diff(birth);

 const years = Math.floor(dayjs.duration(diff).asYears());
 const months = Math.floor(
  dayjs.duration(diff).subtract(years, "years").asMonths()
 );
 const days = Math.ceil(
  dayjs
   .duration(diff)
   .subtract(years, "years")
   .subtract(months, "months")
   .asDays()
 );

 return `Age: ${years} Years ${months} Months ${days} Days`;
}

function getWorkDuration(joinDate, dateResign) {
 if (dayjs(dateResign).isValid()) {
  const now = dayjs(dateResign);
  const join = dayjs(joinDate);
  const diff = now.diff(join);

  const years = Math.floor(dayjs.duration(diff).asYears());
  const months = Math.floor(
   dayjs.duration(diff).subtract(years, "years").asMonths()
  );
  const days = Math.ceil(
   dayjs
    .duration(diff)
    .subtract(years, "years")
    .subtract(months, "months")
    .asDays()
  );

  return `${years} Years ${months} Months ${days} Days`;
 } else {
  const now = dayjs();
  const join = dayjs(joinDate);
  const diff = now.diff(join);

  const years = Math.floor(dayjs.duration(diff).asYears());
  const months = Math.floor(
   dayjs.duration(diff).subtract(years, "years").asMonths()
  );
  const days = Math.ceil(
   dayjs
    .duration(diff)
    .subtract(years, "years")
    .subtract(months, "months")
    .asDays()
  );

  return `${years} Years ${months} Months ${days} Days`;
 }
}
const options = (arrayOptionAnswers) => {
 return arrayOptionAnswers.map((option, index) => (
  <option key={option + index} value={option}>
   {option}
  </option>
 ));
};

function capitalizeEachWord(str) {
 if (typeof str !== "string" || !str) {
  //console.error("Input must be a string,", " capitalizeEachWord: ", str);
  return str;
 }

 return str
  .split(" ") // Split the string into words
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lower case the rest
  .join(" "); // Join the words back into a string
}

function truncateString(str, num = 20) {
 return str.length > num ? str.substring(0, num) + "..." : str;
}

// Fungsi untuk mengonversi detik menjadi jam dan menit
function convertSeconds(durationInSeconds) {
 const hours = Math.floor(durationInSeconds / 3600);
 const minutes = Math.floor((durationInSeconds % 3600) / 60);

 return { hours, minutes };
}

export {
 getAxiosTargetPath,
 getAge,
 getWorkDuration,
 options,
 capitalizeEachWord,
 truncateString,
 convertSeconds,
};
